<template>
    <SmartForm base_url="/document/state" base_path="/document/state" form_type="edit"></SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Stati", route: "/document/state/index" },
            { title: "Modifica Stato" }
        ]);
    }
};
</script>
